var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ValidationObserver",
    { ref: "detalheUsuario", attrs: { tag: "form" } },
    [
      _c(
        "popup-base",
        {
          staticClass: "endereco",
          attrs: {
            size: "lg",
            title: !!(_vm.user || {}).UserInfoId
              ? _vm.canChange
                ? "Editar usuário"
                : "Visualizar usuário"
              : "Adicionar usuário",
            showModal: _vm.showUserModal,
          },
          on: {
            close: function ($event) {
              _vm.showUserModal = false
            },
          },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _c("ValidationProvider", {
                        attrs: { name: "Nome do usuário", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Nome do usuário",
                                    size: "large",
                                    disabled: !_vm.canChange,
                                    danger: errors[0] && _vm.canChange,
                                    "danger-text": errors[0],
                                  },
                                  model: {
                                    value: _vm.dadosUsuario.Name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dadosUsuario, "Name", $$v)
                                    },
                                    expression: "dadosUsuario.Name",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _c("ValidationProvider", {
                        attrs: { name: "CPF do usuário", rules: "cpf" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "###.###.###-##",
                                      expression: "'###.###.###-##'",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "CPF",
                                    size: "large",
                                    disabled: !_vm.canChange,
                                    danger: errors[0] && _vm.canChange,
                                    "danger-text": errors[0],
                                  },
                                  model: {
                                    value: _vm.dadosUsuario.CpfCnpj,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dadosUsuario, "CpfCnpj", $$v)
                                    },
                                    expression: "dadosUsuario.CpfCnpj",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          name: "telefone do usuário",
                          rules: "required|phone",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: [
                                        "(##) ####-####",
                                        "(##) #####-####",
                                      ],
                                      expression:
                                        "['(##) ####-####', '(##) #####-####']",
                                    },
                                  ],
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Telefone",
                                    size: "large",
                                    disabled: !_vm.canChange,
                                    danger: errors[0] && _vm.canChange,
                                    "danger-text": errors[0],
                                  },
                                  model: {
                                    value: _vm.dadosUsuario.PhoneNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dadosUsuario,
                                        "PhoneNumber",
                                        $$v
                                      )
                                    },
                                    expression: "dadosUsuario.PhoneNumber",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          name: "e-mail do usuário",
                          rules: "required|email",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "E-mail",
                                    size: "large",
                                    disabled: !_vm.canChange,
                                    danger: errors[0] && _vm.canChange,
                                    "danger-text": errors[0],
                                  },
                                  model: {
                                    value: _vm.dadosUsuario.Email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dadosUsuario, "Email", $$v)
                                    },
                                    expression: "dadosUsuario.Email",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-1/2" },
                    [
                      _vm.canChange
                        ? _c("ValidationProvider", {
                            attrs: {
                              name: "perfil do usuário",
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "p",
                                        { staticClass: "vs-input--label" },
                                        [_vm._v("Tipo perfil")]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          label: "Name",
                                          size: "large",
                                          placeholder: "Tipo Perfil",
                                          options: _vm.tiposUsuario,
                                          reduce: (perfil) => perfil.UniqueId,
                                          danger: errors[0],
                                          "danger-text": errors[0],
                                        },
                                        model: {
                                          value:
                                            _vm.dadosUsuario.ProfileUniqueId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dadosUsuario,
                                              "ProfileUniqueId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dadosUsuario.ProfileUniqueId",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1336703718
                            ),
                          })
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              label: "Tipo perfil",
                              size: "large",
                              disabled: true,
                            },
                            model: {
                              value: _vm.dadosUsuario.ProfileName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dadosUsuario, "ProfileName", $$v)
                              },
                              expression: "dadosUsuario.ProfileName",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    {
                      staticClass: "w-1/2 container-element",
                      attrs: { "vs-type": "flex", "vs-align": "center" },
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("Ativo"),
                      ]),
                      _c(
                        "vs-switch",
                        {
                          staticClass: "mt-3",
                          attrs: { disabled: !_vm.canChange },
                          model: {
                            value: _vm.dadosUsuario.IsActive,
                            callback: function ($$v) {
                              _vm.$set(_vm.dadosUsuario, "IsActive", $$v)
                            },
                            expression: "dadosUsuario.IsActive",
                          },
                        },
                        [
                          _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                            _vm._v("Sim"),
                          ]),
                          _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                            _vm._v("Não"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("vs-row", [_c("vs-col", [_c("h5", [_vm._v("Endereço")])])], 1),
              _c("form-endereco", {
                attrs: {
                  dadosEndereco: _vm.endereco,
                  disabled: !_vm.canChange,
                  required: false,
                },
                on: { updateValue: _vm.atualizaEndereco },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "vs-row",
                { staticClass: "py-0" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-type": "flex", "vs-justify": "end" } },
                    [
                      _c(
                        "vs-button",
                        {
                          class: { "mr-3": _vm.canChange },
                          attrs: { color: "primary", type: "border" },
                          on: {
                            click: function ($event) {
                              _vm.showUserModal = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.canChange ? "Cancelar" : "Fechar") +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm.canChange
                        ? _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.salvarUsuario()
                                },
                              },
                            },
                            [_vm._v("\n            Aplicar\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }