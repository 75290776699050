<template>
  <div>
    <div class="table-content pt-2">
      <template v-if="usuarios && usuarios.length > 0">
        <vs-table
          class="table-list"
          :pagination="usuarios && usuarios.length > itemsPerPage"
          :max-items="itemsPerPage"
          :data="usuarios || []"
          :hoverFlat="true"
          search
          noDataText="Nenhum usuário encontrado"
        >
          <template slot="thead">
            <vs-th class="text-center" v-if="actions && actions.length">
              Ações
            </vs-th>
            <vs-th>Nome</vs-th>
            <vs-th>E-mail</vs-th>
            <vs-th>Telefone</vs-th>
            <vs-th>Ativo</vs-th>
            <vs-th>Perfil</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="usuario"
              :class="{ 'bg-danger-gradient': !usuario.IsActive }"
              :key="indextr"
              v-for="(usuario, indextr) in data"
            >
              <vs-td v-if="actions && actions.length">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>

                  <vs-dropdown-menu style="width: 200px">
                    <vs-dropdown-item
                      v-for="(userAction, indexAction) in actions"
                      :disabled="
                        userAction.allowedRole ? disabledOption(usuario) : false
                      "
                      :key="indexAction"
                      @click="userAction.action(usuario)"
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{ userAction.name }}</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>

              <vs-td class="text-center">{{ usuario.Name }}</vs-td>
              <vs-td class="text-center">{{ usuario.Email }}</vs-td>
              <vs-td class="text-center">{{ usuario.PhoneNumber }}</vs-td>
              <vs-td class="text-center"
                ><span class="font-semibold">{{
                  usuario.IsActive ? "Ativo" : "Inativo"
                }}</span></vs-td
              >
              <vs-td class="text-center">{{ usuario.ProfileName }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p>Você ainda não possui nenhum tomador cadastrado.</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  props: {
    usuarios: { type: Array, default: () => [] },
    podeGerenciar: { type: Boolean, default: false },
    podeVisualizar: { type: Boolean, default: false }
  },
  data() {
    return {
      itemsPerPage: 10,
      profileHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("auth", ["userInfo"]),
    actions() {
      let actions = [];

      if (
        this.profileHelpers.checkPermission([
          this.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
          this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
        ]) ||
        this.podeVisualizar
      )
        actions.push({
          name: "Visualizar usuário",
          action: this.visualizarUsuario,
          allowedRole: false
        });

      if (
        this.profileHelpers.checkPermission([
          this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
        ]) ||
        this.podeGerenciar
      )
        actions = actions.concat([
          {
            name: "Editar usuário",
            action: this.editarUsuario,
            allowedRole: true
          },
          {
            name: "Ativar/ inativar usuário",
            action: this.statusUsuario,
            allowedRole: true
          },
          {
            name: "Excluir usuário",
            action: this.excluirUsuario,
            allowedRole: true
          }
        ]);

      return actions;
    }
  },
  methods: {
    ...mapActions("usuario-module", [
      "inativarAtivarUsuario",
      "obterTipoDeAcesso",
      "deletarUsuario"
    ]),

    disabledOption(user) {
      if ((user || {}).IsMain && this.profileHelpers.isBroker())
        return user.ProfileUniqueId != this.userInfo.ProfileUniqueId;
      return false;
    },

   async editarUsuario(usuario) {
       await this.$onpoint.loading( async () => {
        return await this.obterTipoDeAcesso(usuario.TypeId);
      }); 
      this.$emit("editarUsuario", usuario);
    },

    visualizarUsuario(usuario) {
      this.$emit("visualizarUsuario", usuario);
    },

    async excluirUsuario(usuario) {
      if (!usuario.UserInfoId) return;
      await this.$onpoint.loadingModal(
        "Aguarde, sua solicitação está sendo processada",
        async () => {
        return  await this.deletarUsuario(usuario.UserInfoId)
            .then(() => {
              this.$emit("exluirUsuario", usuario);
              this.$onpoint.successModal("Usuário excluído com sucesso");
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        }
      );
    },

    async statusUsuario(usuario) {
      await this.$onpoint.loadingModal(
        "Aguarde, solicitação está sendo processada",
         async () => {
          return  await this.inativarAtivarUsuario(usuario.UserInfoId)
            .then(() => {
              this.$onpoint.successModal(
                `Usuário
                ${!usuario.IsActive ? " desativado " : " ativado "}
                com sucesso`
              );
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/mixins";
.container-element {
  position: relative;

  .label-content {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 5px;

    &.label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }
  }

  p {
    padding: 0.5rem 1rem !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    width: 100%;
  }
}

.bg-danger-gradient {
  background: #f7e8e8 !important;
}

.vs-con-table {
  padding: 5px;
}

.table-list {
  margin-top: -32px;
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }
  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }
  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;
    }
    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 300px;      
      height: 47px;
      margin-top: 30px;
    }
  }
  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
