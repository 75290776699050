<template>
  <ValidationObserver ref="detalheUsuario" tag="form">
    <popup-base
      class="endereco"
      size="lg"
      :title="
        !!(user || {}).UserInfoId
          ? canChange
            ? 'Editar usuário'
            : 'Visualizar usuário'
          : 'Adicionar usuário'
      "
      @close="showUserModal = false"
      :showModal="showUserModal"
    >
      <template slot="content">
        <vs-row>
          <vs-col class="w-1/2">
            <ValidationProvider
              name="Nome do usuário"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                label="Nome do usuário"
                size="large"
                class="w-full"
                v-model="dadosUsuario.Name"
                :disabled="!canChange"
                :danger="errors[0] && canChange"
                :danger-text="errors[0]"
              />
            </ValidationProvider>
          </vs-col>

          <vs-col class="w-1/2">
            <ValidationProvider
              name="CPF do usuário"
              rules="cpf"
              v-slot="{ errors }"
            >
              <vs-input
                label="CPF"
                size="large"
                class="w-full"
                v-mask="'###.###.###-##'"
                v-model="dadosUsuario.CpfCnpj"
                :disabled="!canChange"
                :danger="errors[0] && canChange"
                :danger-text="errors[0]"
              />
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/2">
            <ValidationProvider
              name="telefone do usuário"
              rules="required|phone"
              v-slot="{ errors }"
            >
              <vs-input
                label="Telefone"
                size="large"
                class="w-full"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="dadosUsuario.PhoneNumber"
                :disabled="!canChange"
                :danger="errors[0] && canChange"
                :danger-text="errors[0]"
              />
            </ValidationProvider>
          </vs-col>

          <vs-col class="w-1/2">
            <ValidationProvider
              name="e-mail do usuário"
              rules="required|email"
              v-slot="{ errors }"
            >
              <vs-input
                label="E-mail"
                size="large"
                class="w-full"
                v-model="dadosUsuario.Email"
                :disabled="!canChange"
                :danger="errors[0] && canChange"
                :danger-text="errors[0]"
              />
            </ValidationProvider>
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col class="w-1/2">
            <ValidationProvider
              v-if="canChange"
              name="perfil do usuário"
              rules="required"
              v-slot="{ errors }"
            >
              <p class="vs-input--label">Tipo perfil</p>

              <v-select
                label="Name"
                size="large"
                placeholder="Tipo Perfil"
                v-model="dadosUsuario.ProfileUniqueId"
                :options="tiposUsuario"
                :reduce="perfil => perfil.UniqueId"
                :danger="errors[0]"
                :danger-text="errors[0]"
              />
            </ValidationProvider>

            <vs-input
              v-else
              label="Tipo perfil"
              size="large"
              class="w-full"
              v-model="dadosUsuario.ProfileName"
              :disabled="true"
            />
          </vs-col>

          <vs-col
            class="w-1/2 container-element"
            vs-type="flex"
            vs-align="center"
          >
            <p class="label-content label">Ativo</p>

            <vs-switch
              class="mt-3"
              v-model="dadosUsuario.IsActive"
              :disabled="!canChange"
            >
              <span slot="on">Sim</span>
              <span slot="off">Não</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col>
            <h5>Endereço</h5>
          </vs-col>
        </vs-row>

        <form-endereco
          :dadosEndereco="endereco"
          :disabled="!canChange"
          :required="false"
          @updateValue="atualizaEndereco"
        />
      </template>

      <template slot="footer">
        <vs-row class="py-0">
          <vs-col vs-type="flex" vs-justify="end">
            <vs-button
              :class="{ 'mr-3': canChange }"
              color="primary"
              type="border"
              @click="showUserModal = false"
            >
              {{ canChange ? "Cancelar" : "Fechar" }}
            </vs-button>

            <vs-button
              color="primary"
              @click="salvarUsuario()"
              v-if="canChange"
            >
              Aplicar
            </vs-button>
          </vs-col>
        </vs-row>
      </template>
    </popup-base>
  </ValidationObserver>
</template>

<script>
import { mask, TheMask } from "vue-the-mask";
import { mapGetters, mapMutations, mapActions } from "vuex";

import FormEndereco from "@/components/enderecos/FormEndereco.vue";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  directives: { mask },
  components: {
    FormEndereco,
    TheMask
  },
  props: {
    user: { type: Object, default: () => {} },
    canChange: { type: Boolean, default: false },
    showModal: { type: Boolean, default: false },
    userTypeId: {
      type: Number
    }
  },
  data() {
    return {
      dadosUsuario: { IsActive: true },
      userId: "",
      endereco: {},

      profileHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("usuario-module", ["tiposUsuario"]),
    ...mapGetters("auth", ["userInfo"]),

    typeId() {
      return this.userTypeId;
    },

    showUserModal: {
      get() {
        return this.showModal;
      },

      set(val) {
        this.$emit("closeModal", val);

        if (!val) {
          this.$refs.detalheUsuario.reset();
          this.dadosUsuario = { IsActive: true };
          this.endereco = {};
        }
      }
    }
  },
  watch: {
    user(value) {
      if ((value || {}).UserInfoId) {
        this.dadosUsuario = this.$utils.deepClone(value);
        this.endereco = {
          CityId: value.CityId,
          CityName: value.CityName,
          Neighborhood: value.Neighborhood,
          Number: value.Number,
          StateProvinceId: value.StateProvinceId,
          StateProvinceName: value.StateProvinceName,
          ZipCode: value.ZipCode,
          AddressName: value.AddressName
        };
      }
    },

    showUserModal(value) {
      if (value)
        this.dadosUsuario = {
          ...this.$utils.deepClone(this.user),
          ...this.dadosUsuario
        };
    }
  },
  methods: {
    ...mapActions("auth", ["requestRecoveryPassword"]),
    ...mapActions("usuario-module", ["obterTipoDeAcesso", "criarUsuario"]),
    ...mapMutations("usuario-module", ["updateUsuario"]),
   async salvarUsuario() {
      await this.$refs.detalheUsuario.validate().then( async isValid => {
        if (isValid)
        await this.$onpoint.loadingModal(
            "Aguarde, sua solicitação está sendo processada",
             async () => {
              return await this.criarUsuario({
                TypeId: this.typeId,
                ...this.dadosUsuario,
                ...this.endereco
              })
                .then(() => {
                  this.$onpoint.successModal("Usuário salvo com sucesso.");

                  if (this.user.UserInfoId == this.userInfo.UserId) {
                    authUtils.logout();
                  }

                  // if (!this.user.UserInfoId) {
                  //   this.sendRecoveryEmail(this.dadosUsuario.Email);
                  // }

                  this.$emit("loadUsuarios");
                  this.showUserModal = false;
                })
                .catch(errors => {
                  this.$onpoint.errorModal(errors.response.data.Errors);
                });
            }
          );
      });
    },

    sendRecoveryEmail(email) {
      return this.requestRecoveryPassword({
        Username: email
      })
        .then(() => {
          this.$onpoint.successModal(
            "Link de recuperação de senha enviado. Enviamos um link de recuperação de senha para o seu e-mail."
          );
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },

    atualizaEndereco(value) {
      if (this.endereco != value) this.endereco = value;
    }
  },
};
</script>

<style lang="scss">
.container-element {
  position: relative;

  .label-content {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 5px;
  }
  .label {
    transform: translateY(-23px);
    transform-origin: top-left;
    position: absolute;
  }
}

.input-icon-validate-danger .input-icon-validate {
  display: none;
}
</style>
